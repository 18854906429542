import React, { useState } from "react";
import "./style1.css";
import FsLightbox from "fslightbox-react";
const Portfolio3 = (props) => {
  const [toggler, setToggler] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const toggleToggler = (item) => {
    setToggler(!toggler);
    setActiveItem(item);
  };
  const portfolioItems = [
    { id: 1, image: "/assets/images/portfolio/ecom/ecom1.webp", alt: "portfolio1" },
    { id: 2, image: "/assets/images/portfolio/ecom/ecom2.webp", alt: "portfolio2" },
    { id: 3, image: "/assets/images/portfolio/ecom/ecom3.webp", alt: "portfolio3" },
    { id: 4, image: "/assets/images/portfolio/ecom/ecom4.webp", alt: "portfolio4" },
    { id: 5, image: "/assets/images/portfolio/ecom/ecom5.webp", alt: "portfolio5" },
    { id: 6, image: "/assets/images/portfolio/ecom/ecom6.webp", alt: "portfolio6" },
  ];

  const itemsToDisplay = portfolioItems.slice(0, props.limit);

  return (
    <div>
      <section className="portfolioboxes">
        <div className="container-custom">
          <div classname="row">
            <div className="col-lg-12">
              <div className="portwrappp">
                <div className="tab-custom">
                <ul className="tabs tabs-logoport mportt">
                    {itemsToDisplay.map((item) => (
                      <li className="to-win-box" key={item.id}>
                        <figure>
                          <img onClick={() => toggleToggler(item)} src={item.image} alt={item.alt} />
                        </figure>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {props.showViewMoreButton && ( // Check the prop here
          <div class="row">
            <div class="col-lg-12">
              <div class="view-more-btn mt--60 mt_sm--30 text-center">
                <a class="btn-default" href="/portfolio">
                  <span>View More</span>
                </a>
              </div>
            </div>
          </div>
            )}
        </div>
        <FsLightbox toggler={toggler} sources={[activeItem ? activeItem.image : ""]}/>
      </section>
    </div>


  )
}
export default Portfolio3;