import React, { Component } from "react";
import "./style1.css";
import { FiCheck } from "react-icons/fi";
const portfolio2 = () => {
    return (
       <div>
        <section className="portfolioboxes">
        <div className="container-custom">
          <div classname="row">
          <div className="col-lg-12">
              <div className="portwrappp">
                <div className="tab-custom">
              <ul className="tabs tabs-ecomport mportt">
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/1in.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/1.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/2in.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/2.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/3in.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/3.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/4in.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/4.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/5in.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/5.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/6in.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/6.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/7in.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/7.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/8in.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/8.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/9in.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/ecommerce/9.jpg" />
            </figure>
          </a>
        </li>
      </ul>
      </div>
</div>
</div>
</div>
</div>
</section>
       </div>


    )
}
export default portfolio2;