import React, { Component } from "react";
import {FiCheck } from "react-icons/fi";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import BrandTwo from "../elements/BrandTwo";
import { Link } from "react-router-dom";
import Packages from '../blocks/package';

class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const { title, parent } = this.props;
        return(
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Website Design And Development' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

               {/* Start Breadcrump Area */}
               <div className="breadcrumb-area-our  ptb--120 bg_image bg_image--20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    <h2 className="title">Website Design And Development</h2>
                                    <ul className="page-list">
                                        <li className="rn-breadcrumb-item"><Link to="/">Home</Link></li>
                                        {parent ? <li className="rn-breadcrumb-item">{parent}</li> : ''}
                                        <li className="rn-breadcrumb-item active">Website Design And Development</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/webdeisng1.jpg" alt="Service Images"/>
                                    
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <span className="subtitle">Crafting Outstanding Design for Your Virtual Presence</span>
                                                        <h2 className="title">Website Design</h2>
                                                        <p className="description">At Programatic Logic, we're committed to delivering custom web design solutions that authentically reflect our client's businesses. Recognizing the significance of a standout digital presence, our team of skilled designers and developers is dedicated to crafting visually stunning, user-friendly websites. Our goal is to make your site look great and ensure it effectively represents your brand and aids in achieving your business objectives in today's digital landscape.</p>
                                                        <p className="description">We adopt a holistic approach to web design, merging aesthetics with psychology for a user experience that is not only visually appealing but also functionally effective. Our designs aim for both subjective beauty and objective functionality. For custom solutions differentiating your business, choose Programatic Logic, your one-stop solution for web designing needs.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                    <span className="subtitle">Boosting Businesses with Dynamic Website Solutions</span>
                                                        <h2 className="title">Website Development</h2>
                                                        <p className="description">At Programatic Logic, our team comprises highly experienced web designers and developers adept at transforming designs into functional websites. With a profound grasp of the technical facets of web development, our developers excel at working across diverse platforms, ensuring your website is flawlessly operational and responsive.</p>
                                                        <p className="description">We embrace a collaborative approach to website development. Our close partnership with clients ensures the realization of project goals and vision. Understanding the unique needs of each project, we explore diverse solutions together, committed to delivering top-quality products. We actively involve clients in the development process, valuing their feedback and suggestions for a constructive and client-centric experience.</p>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                <img className="w-100" src="/assets/images/service/webdeisng1.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}


                 {/* Start Brand Area */}
                 <div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Our Expertise</span>
                                    <h2 className="title">Technologies We Use</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--20">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}


                {/* Start Pricing Tabale Area  */}
                <Packages  name='web' />
                {/* End Pricing Tbale Area  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;