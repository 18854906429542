import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                    <img src="/assets/images/technology/react.png" style={{ filter: "none", opacity: "10" }} alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/technology/javascript.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technology/java.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technology/laravel.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technology/nodejs.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technology/bootstrap.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technology/python.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technology/sql.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technology/angular.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technology/html-5.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technology/vujs.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technology/css3.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technology/sass.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technology/tailwind.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technology/wordpress.png" style={{ filter: "none" }} alt="Logo Images"/>
                    </li>
                  
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;