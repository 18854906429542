import React, { Component } from "react";
import FormStyle from './FormStyle';
import { FiChevronUp, FiCheck } from "react-icons/fi";

const services = [
    {
        id: 1,
        title: 'Logo Basic',
        slug: 'logo-basic',
        price: '22257 PKR',
        includes: [
            '6 Unique Logo Concepts',
            'Receive Initial Concepts within 24 hours',
            'Unlimited Revisions',
            'Free Icon',
            '100% Ownership Rights',
            'PSD, BMP, JPEG, AI, EPS, GIF, PNG Formats',
        ],
        features: [
            'Free Rush Delivery',
            '100% Satisfaction Guaranteed',
            'Dedicated Designers',
            'Guaranteed Unique Designs',
            'Tailored to Your Needs',
        ],
    },
    {
        id: 2,
        title: 'Logo Plus',
        slug: 'logo-plus',
        price: '35889 PKR',
        includes: [
            '12 Unique Logo Concepts',
            'Receive Initial Concepts within 24 hours',
            'Unlimited Revisions',
            'Free Icon',
            '100% Ownership Rights',
            'Stationery Design (Business Card, Letterhead, Envelope)',
            'PSD, BMP, JPEG, AI, EPS, GIF, PNG Formats',
        ],
        features: [
            'Free Rush Delivery',
            '100% Satisfaction Guaranteed',
            'Dedicated Designers',
            'Guaranteed Unique Designs',
            'Tailored to Your Needs',
        ],
    },
    {
        id: 3,
        title: 'Logo Infinity',
        slug: 'logo-infinity',
        price: '83186 PKR',
        includes: [
            'Unlimited Logo Concepts',
            'Receive Initial Concepts within 24 hours',
            'Infinite Revisions',
            'Free Icon',
            '100% Ownership Rights',
            'Stationery Design (Business Card, Letterhead, Envelope)',
            'Free 500 Business Cards Prints',
            'Printing',
            'PSD, BMP, JPEG, AI, EPS, GIF, PNG Formats',
        ],
        features: [
            'Free Rush Delivery',
            '100% Satisfaction Guaranteed',
            'Dedicated Designers',
            'Guaranteed Unique Designs',
            'Tailored to Your Needs',
        ],
    },
    {
        id: 4,
        title: 'Web Basic',
        slug: 'web-basic',
        price: '83186 PKR',
        includes: [
            '5-Page Website Package',
            '1 Unique Design Concept',
            'Assigned Dedicated Designer',
            'Completed Within Two to Three Weeks',
            'Includes 4 Stock Photos',
            'Free On-Page SEO',
            'Optional CMS Addition for $99',
            'We Guarantee 100% Satisfaction',
        ],
    },
    {
        id: 5,
        title: 'Web Basic Plus',
        slug: 'web-basic-plus',
        price: '152739 PKR',
        includes: [
            'Comprehensive 7-Page Website Package',
            '2 Distinctive Design Concept',
            'Assigned Dedicated Designer',
            'Completed Within Two to Three Weeks',
            'Includes 7 Stock Photos',
            'Free On-Page SEO',
            'W3C Certified HTML',
            'Included CMS Addition',
            'Backed by Our 100% Satisfaction Guarantee',
        ],
    },
    {
        id: 6,
        title: 'Web Basic Premium',
        slug: 'web-basic-premium',
        price: '208382 PKR',
        includes: [
            'All-Inclusive Unlimited Pages Website Package',
            '3 Unique Design Concept',
            'Assigned Dedicated Designer',
            'Completed Within Two to Three Weeks',
            'Includes 10 Stock Photos',
            'Free On-Page SEO',
            'Complimentary 1-Year Web Hosting',
            'Included CMS Addition',
            'Our Commitment: 100% Satisfaction Guarantee',
        ],
    },
    {
        id: 7,
        title: 'WooCommerce',
        slug: 'woocommerce-development',
        price: '277936 PKR',
        includes: [
            '1 Layout Design',
            '2 Design Revision',
            'Responsive/Mobile-Friendly Design',
            'SEO-Friendly Implementation',
            '10 Hours of Included Support',
            'Unlimetied Products',
            'Order Management',
            'Catalog Management',
            'Inventory Management',
            'Website Management Features',
            'Checkout, Payment & Shipping Integration',
            'Analytics Implementation',
            'Payment Options: Paypal, Google Checkout, Credit/Debit Card',
            'Customer Service Chat Bot',
            'Shipping Methods Integration',
            'Tax Charges',
            'Sitemaps (XML)',
            'Product Browsing',
            'Product Search',
            'Product Reviews',
            'Social Media Integration',
            'Training (Optional, additional hours at $25/hour)',
            'CMS - WordPress Blog',
            'Custom Contact Us',
            'Newsletter Subscription Form',
            'Additional Integration $20/hour'
        ],
    },
    {
        id: 8,
        title: 'Shopify',
        slug: 'shopify-development',
        price: '417043 PKR',
        includes: [
            '1 Layout Design',
            '3 Design Revision',
            'Responsive/Mobile-Friendly Design',
            'SEO-Friendly Implementation',
            '10 Hours of Included Support',
            'Unlimetied Products',
            'Order Management',
            'Catalog Management',
            'Inventory Management',
            'Website Management Features',
            'Checkout, Payment & Shipping Integration',
            'Analytics Implementation',
            'Payment Options: Paypal, Google Checkout, Credit/Debit Card',
            'Customer Service Chat Bot',
            'Shipping Methods Integration',
            'Tax Charges',
            'Sitemaps (XML)',
            'Product Browsing',
            'Product Search',
            'Product Reviews',
            'Social Media Integration',
            'Training (Optional, additional hours at $25/hour)',
            'CMS - WordPress Blog',
            'Custom Contact Us',
            'Newsletter Subscription Form',
            'Additional Integration $20/hour'
        ],
    },
    {
        id: 9,
        title: 'Laravel',
        slug: 'laravel-development',
        price: '556151 PKR',
        includes: [
            'Custom Design',
            '3 Design Revision',
            'Responsive/Mobile-Friendly Design',
            'SEO-Friendly Implementation',
            '12 Hours of Included Support',
            'Unlimetied Products',
            'Advance Order Management',
            'Advance Catalog Management',
            'Advance Inventory Management',
            'Website Management Features',
            'Checkout, Payment & Shipping Integration',
            'Analytics Implementation',
            'Payment Options: Paypal, Google Checkout, Credit/Debit Card',
            'Customer Service Chat Bot',
            'Shipping Methods Integration',
            'Tax Charges',
            'Sitemaps (XML)',
            'Advance Product Browsing',
            'Advance Product Search',
            'Product Reviews',
            'Social Media Integration',
            'Training (Optional, additional hours at $25/hour)',
            'Custom CMS',
            'Custom Payment Gateway',
            'Customized Inner Pages',
            'Custom Contact Us',
            'Newsletter Subscription Form',
            'Promo Popup,Design and Configuration',
            '3rd Party Extensions Integration (Optional, additional $20/hour)'
        ],
    },
];

class OrderForm extends Component {
    render() {
        const prop = this.props;

        const findServiceById = (slug) => {
            return services.find(service => service.slug === slug);
        }

        const service = findServiceById(prop.slug);

        console.log(service);
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--30">
                                <h2 className="title">Order Information</h2>
                            </div>
                            <FormStyle />
                        </div>
                        {/* {service.map(item => ( */}
                            <div key={service.id} className="col-lg-6 order-1 order-lg-2 m-auto">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">{service.title}</h4>
                                            <div className="pricing">
                                                <span className="price">{service.price}</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                {service.includes.map((item, index) => (
                                                    <li key={index}><FiCheck /> {item}</li>
                                                ))}
                                                {service?.features ? (
                                                    <h2>Features</h2>
                                                ) : null}
                                                {service?.features?.map((feature, index) => (
                                                    <li key={index}><FiCheck /> {feature}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/* ))} */}
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderForm;