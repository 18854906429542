import React, { Component } from "react";
import {FiCheck } from "react-icons/fi";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import { Link } from "react-router-dom";
import Packages from '../blocks/package';

class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const { title, parent } = this.props;
        return(
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Logo Design' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

               {/* Start Breadcrump Area */}
               <div className="breadcrumb-area-our  ptb--120 bg_image bg_image--17">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    <h2 className="title">Logo Design</h2>
                                    <ul className="page-list">
                                        <li className="rn-breadcrumb-item"><Link to="/">Home</Link></li>
                                        {parent ? <li className="rn-breadcrumb-item">{parent}</li> : ''}
                                        <li className="rn-breadcrumb-item active">Logo Design</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--100 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/logodeisng1.jpg" alt="Service Images"/>
                                    
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <span className="subtitle">We Create</span>
                                                        <h2 className="title">Logos That Leave a Lasting Impression</h2>
                                                        <p className="description">Programatic Logic, a distinguished logo design firm, has delighted clients globally, serving startups to MNCs. We recognize a great logo's pivotal role in business. Hence, our dynamic design team consistently delivers ingenious and effective logos, understanding and fulfilling your specific desires.</p>
                                                        <p className="description">Experience top-notch logo design services, essential for impactful branding and forging a unique brand identity. Our logos artfully express the brand's core values, weaving a narrative that seamlessly blends aesthetics and functionality to create wonders.</p>
                                                        <p className="description">Explore diverse logo design types for your business, enhanced with our expertise to add creativity and eye-catching appeal.</p>
                                                    </div>
                                                    {/* <ul className="liststyle mt--30">
                                                        <div className="d-flex">
                                                            <li>Mascot</li>
                                                            <li>Combination Mark</li>
                                                       </div>
                                                        <div className="d-flex">
                                                            <li>Abstract</li>
                                                            <li>Emblem</li>
                                                        </div>
                                                        <li>Icon</li>
                                                        <li>Wordmark</li>
                                                        <li>Lettermark</li>

                                                    </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        <h2 className="title">What sets us apart as experts in crafting creative logos?</h2>
                                                        <p className="description">At Programatic Logic, our adept team of image designers understands the power of visual communication and brand identity. We analyze your logo, audience, and business meticulously, crafting a brand that captures your unique essence and resonates with your clients. Our creative process involves brainstorming innovative ideas, sketching concepts, and refining them until we achieve the perfect blend of creativity and professionalism.</p>
                                                        <p className="description">We stay updated with cutting-edge design trends, striving for timeless appeal and ensuring your logo remains relevant and impactful. Our commitment to excellence, attention to detail, and unwavering dedication to customer satisfaction make us the prime choice for developing a creative brand to elevate your identity and leave a lasting impression on your audience.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                <img className="w-100" src="/assets/images/service/logodeisng1.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Pricing Tbale Area  */}
                <Packages  name='design' />
                {/* End Pricing Tbale Area  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;