import React, { Component } from "react";
import "./style.css";
import { FiCheck } from "react-icons/fi";
const portfolio5 = () => {
    return (
        <div>
        <section className="portfolioboxes">
        <div className="container-custom">
          <div classname="row">
          <div className="col-lg-12">
              <div className="portwrappp">
                <div className="tab-custom">
                <ul className="tabs tabs-mobport mportt">
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/1.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/1.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/2.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/2.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/3.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/3.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/4.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/4.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/5.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/5.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/6.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/6.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/7.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/7.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/8.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/8.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/9.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/mobile/9.jpg" />
            </figure>
          </a>
        </li>
      </ul>

                </div>
                </div>
                </div>
                </div>
                </div>
                </section>
       </div>


    )
}
export default portfolio5;