import React, { useState } from "react";
import "./style1.css";
import FsLightbox from "fslightbox-react";
const Portfolio2 = (props) => {
  const [toggler, setToggler] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const toggleToggler = (item) => {
    setToggler(!toggler);
    setActiveItem(item);
  };
  const portfolioItems = [
    { id: 1, image: "/assets/images/portfolio/web-dev/web1.webp", alt: "web-portfolio1" },
    { id: 2, image: "/assets/images/portfolio/web-dev/web2.webp", alt: "web-portfolio2" },
    { id: 3, image: "/assets/images/portfolio/web-dev/web3.webp", alt: "web-portfolio3" },
    { id: 4, image: "/assets/images/portfolio/web-dev/web4.webp", alt: "web-portfolio4" },
    { id: 5, image: "/assets/images/portfolio/web-dev/web5.webp", alt: "web-portfolio5" },
    { id: 6, image: "/assets/images/portfolio/web-dev/web6.webp", alt: "web-portfolio6" },
    { id: 7, image: "/assets/images/portfolio/web-dev/web7.webp", alt: "web-portfolio7" },
    { id: 8, image: "/assets/images/portfolio/web-dev/web8.webp", alt: "web-portfolio8" },
    { id: 9, image: "/assets/images/portfolio/web-dev/web9.webp", alt: "web-portfolio9" },
    { id: 10, image: "/assets/images/portfolio/web-dev/web10.webp", alt: "web-portfolio10" },
    { id: 11, image: "/assets/images/portfolio/web-dev/web11.webp", alt: "web-portfolio11" },
    { id: 12, image: "/assets/images/portfolio/web-dev/web12.webp", alt: "web-portfolio12" },
    { id: 13, image: "/assets/images/portfolio/web-dev/web13.webp", alt: "web-portfolio13" },
    { id: 14, image: "/assets/images/portfolio/web-dev/web14.webp", alt: "web-portfolio14" },
    { id: 15, image: "/assets/images/portfolio/web-dev/web15.webp", alt: "web-portfolio15" },
    { id: 16, image: "/assets/images/portfolio/web-dev/web16.webp", alt: "web-portfolio16" },
    { id: 17, image: "/assets/images/portfolio/web-dev/web17.webp", alt: "web-portfolio17" },
    { id: 18, image: "/assets/images/portfolio/web-dev/web18.webp", alt: "web-portfolio18" },
    { id: 19, image: "/assets/images/portfolio/web-dev/web19.webp", alt: "web-portfolio19" },
    { id: 20, image: "/assets/images/portfolio/web-dev/web20.webp", alt: "web-portfolio20" },
    { id: 21, image: "/assets/images/portfolio/web-dev/web21.webp", alt: "web-portfolio21" },
    { id: 22, image: "/assets/images/portfolio/web-dev/web22.webp", alt: "web-portfolio22" },
    { id: 23, image: "/assets/images/portfolio/web-dev/web23.webp", alt: "web-portfolio23" },
    { id: 24, image: "/assets/images/portfolio/web-dev/web24.webp", alt: "web-portfolio24" },
    { id: 25, image: "/assets/images/portfolio/web-dev/web25.webp", alt: "web-portfolio25" },
    { id: 26, image: "/assets/images/portfolio/web-dev/web26.webp", alt: "web-portfolio26" },
    { id: 27, image: "/assets/images/portfolio/web-dev/web27.webp", alt: "web-portfolio27" },
    { id: 28, image: "/assets/images/portfolio/web-dev/web28.webp", alt: "web-portfolio28" }

  ];

  const itemsToDisplay = portfolioItems.slice(0, props.limit);

  return (
    <div>
      <section className="portfolioboxes">
        <div className="container-custom">
          <div classname="row">
            <div className="col-lg-12">
              <div className="portwrappp">
                <div className="tab-custom">
                <ul className="tabs tabs-logoport mportt">
                    {itemsToDisplay.map((item) => (
                      <li className="to-win-box" key={item.id}>
                        <figure>
                          <img onClick={() => toggleToggler(item)} src={item.image} alt={item.alt} />
                        </figure>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {props.showViewMoreButton && ( // Check the prop here
          <div class="row">
            <div class="col-lg-12">
              <div class="view-more-btn mt--60 mt_sm--30 text-center">
                <a class="btn-default" href="/portfolio">
                  <span>View More</span>
                </a>
              </div>
            </div>
          </div>
            )}
        </div>
        <FsLightbox toggler={toggler} sources={[activeItem ? activeItem.image : ""]}/>
      </section>
    </div>


  )
}
export default Portfolio2;