import React, { Component } from "react";
import "./style1.css";
import { FiCheck } from "react-icons/fi";
const portfolio3 = () => {
    return (
       <div>
       <section className="portfolioboxes">
        <div className="container-custom">
          <div classname="row">
          <div className="col-lg-12">
              <div className="portwrappp">
                <div className="tab-custom">
                <ul className="tabs tabs-webport current mportt">
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/uploads/portfolio/6136906-Drayton_Homepage_Rev01.png">
            <figure>
              <img height="300px" src="https://www.softbitdigital.com/front-end/assets/images/Thumb-1.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/uploads/portfolio/2639862-Home02.jpg">
            <figure>
              <img height="300px" src="https://www.softbitdigital.com/front-end/assets/images/Thumb-2.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/uploads/portfolio/2582421-James_Homepage.jpg">
            <figure>
              <img height="300px" src="https://www.softbitdigital.com/front-end/assets/images/Thumb-3.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/uploads/portfolio/919609-Hair_Slayed_Homepage.jpg">
            <figure>
              <img height="300px" src="https://www.softbitdigital.com/front-end/assets/images/Thumb-4.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/1.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/1.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/2.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/2.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/3.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/3.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/4.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/4.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/5.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/5.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/6.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/6.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/7.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/7.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/8.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/8.jpg" />
            </figure>
          </a>
        </li>
        <li className="to-win-box">
          <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/9.jpg">
            <figure>
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/web/9.jpg" />
            </figure>
          </a>
        </li>
      </ul>
                    </div>
                    </div>
                    </div>
                    </div>
                </div>
                </section>

       </div>


    )
}
export default portfolio3;