import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
// import TermsAndCondition from "../elements/TermsAndCondition";
import { Link } from "react-router-dom";


class About extends Component {
    render() {
        const { title, parent } = this.props;
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Terms & Conditions' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />


                {/* Start Breadcrump Area */}
                <div className="breadcrumb-area-our  ptb--120 bg_image bg_image--22">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    <h2 className="title">Terms & Conditions</h2>
                                    <ul className="page-list">
                                        <li className="rn-breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                        {parent ? <li className="rn-breadcrumb-item">{parent}</li> : ''}
                                        <li className="rn-breadcrumb-item active">Terms & Conditions</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start termsandcondition area */}
                <section className="ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <span className="subtitle"  style={{ fontSize: '40px' }}>Standard Terms and Conditions</span>
                                    <p className="description">These terms and conditions, standardized for website design and development, apply to all contracts and encompass all work undertaken by Programatic Logic for its valued clients.</p>
                                </div>
                                
                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Disclaimer</h4>
                                    <p className="description">The information provided on this website is for general informational purposes only. While our team at Programatic Logic endeavors to maintain accurate and current information, we do not make any representations or warranties, whether express or implied, regarding the accuracy, completeness, suitability, reliability, or availability of the website's content or the information, products, services, or related graphics presented on the website for any purpose. Hence, any trust you place in such information is entirely at your own risk.</p>
                                    <p className="description">We will not be held responsible for any loss or damage, including indirect or consequential loss or damage, arising out of or in connection with the use of this website. This disclaimer includes any loss of data or profits.</p>

                                    <p className="description">Through this website, you have the ability to access links to other websites that are not within the control of Programatic Logic. We do not have authority over the nature, content, and availability of those external sites. The inclusion of any links does not necessarily indicate a recommendation or endorse the views expressed within those linked sites.</p>
                                    <p className="description">While we make every effort to maintain the smooth operation of the website, Programatic Logic assumes no responsibility for, and will not be held liable for, any temporary unavailability of the website due to technical problems beyond our control.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Intellectual Property Rights and Ownership</h4>
                                    <p className="description">The website, along with all materials provided (excluding any applicable third-party materials), is the exclusive property of Programatic Logic. By accessing the website, you acknowledge and agree that all copyright, patent, trademark, trade secret, and other intellectual property rights associated with the materials shall remain the sole property of Programatic Logic. You commit to complying with all worldwide copyright laws in your use of this website and to prevent any unauthorized copying or distribution of the materials.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Purpose and Usage Restrictions</h4>
                                    <p className="description">The Web Site is intended solely for informational purposes and should be utilized in adherence to all relevant laws and regulations. You have the permission to access, use, and exhibit the Web Site and the Materials exclusively for personal, non-commercial purposes. Any replication, modification, distribution, display, transmission, performance, reproduction, publication, licensing, creation of derivative works, transfer, or sale of any information, software, products, or services obtained from this Web Site is strictly prohibited without the express written consent of Programatic Logic.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Applicable Law and Jurisdiction</h4>
                                    <p className="description">Programatic Logic oversees the control, operation, and administration of this Web site from its offices in Pakistan. There is no representation that the materials on this website are suitable or accessible for use in any other location(s) outside Pakistan. Accessing the website from territories where its contents are deemed illegal is strictly prohibited. You consent that the laws of Pakistan will govern any disputes arising under these terms and conditions without giving effect to their conflict of law provisions. Additionally, you agree that the appropriate court(s) in Pakistan will have exclusive jurisdiction to resolve any disputes, and you hereby express your consent to the personal jurisdiction of such court(s).</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">The Full Agreement and Severability Clause</h4>
                                    <p className="description">The terms and conditions presented herein encompass the entirety of the agreement between you and Programatic Logic concerning your utilization of the website, surpassing any preceding agreements or understandings. In the event that any provision(s) of this agreement is deemed by a competent court to be contrary to law, said provision(s) shall be separated from the agreement, while the remaining provisions shall retain their full force and effect.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Confidentiality</h4>
                                    <p className="description">We commit not to sell, share, or rent your personal information to any third party, nor will we utilize your email address for unsolicited mail. Any emails sent by Programatic Logic will solely pertain to the provision of agreed-upon services and products.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Deposits and Our Fees</h4>
                                    <p className="description">A <span style={{ color: 'red' }}>50%</span> initial payment of the total fee outlined in our proposal is required upon your confirmation to proceed with the website design and development. The remaining <span style={{ color: 'red' }}>50%</span> will be invoiced upon the completion of the work to your reasonable satisfaction, in accordance with the terms specified in the "approval of work" and "rejected work" clauses. Please note that we retain the right to withhold the commencement of any work until the full deposit has been settled.</p>
                                    <p className="description">However, if the development of your project begins and you don't want to proceed or want to terminate the contract without any fault on our part, then the deposit will become non-refundable.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Extra Changes/Revisions</h4>
                                    <p className="description">We are delighted to provide you with the chance to revise the design. Yet, we retain the right to set a reasonable limit on the number of design proposals. Additional charges may apply if you request changes beyond the original design specification.</p>
                                    <p className="description">The website development phase allows for flexibility and certain variations from the original specification. However, significant deviations from the specification will incur a charge at the rate of <span style={{ color: 'red' }}>$100.00</span> per hour.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Materials Provision</h4>
                                    <p className="description">It is imperative that you provide all the necessary materials and information required for us to fulfill the work as per the agreed-upon specifications. These materials may encompass, but are not restricted to, photographs, logos, content, and other printed material. In instances where there is a delay in supplying these materials, resulting in a setback in the completion of the work, we reserve the right to reasonably extend any previously agreed deadlines.</p>
                                    <p className="description">If the failure to provide necessary materials hinders the work's progress, we maintain the right to generate an invoice for any portion or stages of the work that has already been completed.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Client Liability and Project Delays</h4>
                                    <p className="description">Any timelines or estimates provided by us rely on your full cooperation and the timely provision of the complete and final content, including photography for the work pages. In the course of development, feedback is essential to advance to subsequent phases. It is imperative to designate a single point of contact from your side who is available daily to facilitate the feedback process.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Approval of Work</h4>
                                    <p className="description">Upon the conclusion of the work, you will be informed and provided with an opportunity for review. If there are any points deemed unsatisfactory, you must communicate them in writing within 7 days of the notification. Any aspects of the work not reported as unsatisfactory within this 7-day review period will be considered approved. Once the work is approved or deemed approved, it is considered final, and the contract is regarded as completed. Subsequently, the remaining 50% of the project price will become due.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Rejected Work</h4>
                                    <p className="description">In the event that you reject any of our work during the 7-day review period or fail to approve subsequent work aimed at addressing noted unsatisfactory points, and we, in a reasonable manner, perceive your rejection as unreasonable, we reserve the right to potentially terminate this contract. If such termination occurs, we may take appropriate measures to recover payment for the completed work, ensuring fair compensation for the services provided.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Your Assurance of Intellectual Property Ownership</h4>
                                    <p className="description"> It is imperative that you obtain all necessary permissions and authorities concerning the use of all copies, images, registered company logos, trademarks, names, or any other material that you supply to us for incorporation into your website or web applications.</p>
                                    <p className="description">As part of your obligation, you must indemnify us and hold us harmless from any claims or legal actions that may arise in connection with the content of your website. This indemnification ensures that we are protected from any liabilities related to the materials provided by you for the development of your website or web applications.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Licensing</h4>
                                    <p className="description"> Upon the complete payment for our services, we extend to you a license that allows you to utilize the website, its related software, and its contents throughout the entire lifespan of the website. This license will ensure your ongoing access and utilization of the developed website and its components for the entirety of its existence.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Search Engines</h4>
                                    <p className="description"> We do not provide a guarantee for a specific position in search engine results for your website. Our efforts include basic search engine optimization in line with current best practices. While we strive to optimize your website for search engines, the dynamic nature of search algorithms and external factors beyond our control prevent us from offering a specific ranking guarantee.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Consequential Loss</h4>
                                    <p className="description"> We want to make it clear that we shall not bear liability for any loss or damage that you may suffer, in any manner, arising from delays in the performance or completion of our contract, regardless of the circumstances leading to such delays. It is essential to understand that unforeseen circumstances or external factors may impact the timeline, and while we aim for efficient delivery, we cannot be held responsible for delays that may occur during the execution of our contractual obligations.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Subcontracting</h4>
                                    <p className="description">We maintain the authority to subcontract any services that we have agreed to perform for you as deemed appropriate. This flexibility allows us to engage external resources or partners to ensure the effective and efficient delivery of the services outlined in our agreement with you. This subcontracting arrangement enables us to tap into specialized expertise and resources, enhancing our ability to meet and exceed the agreed-upon standards and requirements.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Additional Expenses</h4>
                                    <p className="description">You acknowledge the obligation to reimburse us for any requested expenses that fall outside the scope of our initial proposal. These expenses may include but are not limited to, the acquisition of templates, web hosting, stock photographs, domain, font name registration, third-party software, or similar costs. This reimbursement ensures that any additional expenditures incurred for specific project needs are covered and contributes to the seamless execution of the agreed-upon services.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Backups</h4>
                                    <p className="description">It is your responsibility to uphold backups for your website, and we shall not be held liable for restoring any client data or client websites, except in cases where such data loss results from a negligent act or omission on our part. This stipulation emphasizes the importance of your proactive involvement in safeguarding essential data through regular and secure backup practices.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Web Hosting and Domain Ownership</h4>
                                    <p className="description">Upon your reimbursement for any incurred expenses, we will provide you with the account credentials for the domain name registration and/or web hosting services that we procured on your behalf. This ensures transparency and access to essential information related to the purchased services, allowing you to have control and visibility over the respective accounts.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Cross-Browser Compatibility</h4>
                                    <p className="description">To ensure optimal compatibility with contemporary web browsers, including the latest versions of Internet Explorer, Safari, Google Chrome, and Firefox, we leverage the latest releases of well-supported content management systems like "WordPress." It's crucial to note that third-party extensions may not uniformly support all browsers. In instances where incompatibilities arise, we commit to making diligent efforts and employing alternative extensions or other solutions on a best-effort basis. Our aim is to deliver a seamless and accessible browsing experience across diverse platforms.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">E-Commerce</h4>
                                    <p className="description">You are required to adhere to all applicable laws related to e-commerce, and to the fullest extent permitted by law, you will hold ITS and its subcontractors harmless, protect, defend, and indemnify them against any claims, losses, tariffs, taxes, penalties, or damages arising from your or your clients' use of Internet electronic commerce.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Closing Note:</h4>
                                    <p className="description">We appreciate your time in reviewing our terms and conditions. Should you have any inquiries or need additional clarification, feel free to contact us. We value transparency and aim to provide a clear understanding of our services and expectations. Your partnership with us is important, and we look forward to serving you.</p>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
                {/* End termsandcondition area */}

                

               


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default About