import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Link } from 'react-router-dom';
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import SliderTwo from "../component/slider/SliderTwo";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import Portfolio1a from '../elements/portfolio/portfolio1a';
import Portfolio2 from '../elements/portfolio/portfolio2';
import Portfolio3 from '../elements/portfolio/portfolio3';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

class MainDemo extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <Fragment> 
                <Helmet pageTitle="Home" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <SliderTwo />
                </div>
                
                {/* End Slider Area   */}
                
                {/* Start About Area */}
                <div className="about-area ptb--120">
                    <About/>
                </div>
                {/* End About Area */}

                <div className="service-area ptb--120  bg_color--10">
                    <ServiceTwo />
                </div>

              {/* Start Portfolio Area */}
              <div className="portfolio-area ptb--120 bg_color--1">
                <div className="container">
                <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Our Innovation Gallery</span>
                                    <h2 className="title">Strategic Solutions for a Dynamic World</h2>
                                    <p className="description">Boasting a formidable portfolio, we've consistently delivered exceptional solutions worldwide. Collaborating with diverse brands, our tailored solutions propel businesses to thrive effortlessly in this digital era.</p>
                                </div>
                            </div>
                        </div>
                    <Tabs defaultActiveKey="web" id="fill-tab-example" className="mb-3 mt-5 " fill>

                        <Tab eventKey="web" title="Logo Design">
                        <Portfolio1a limit={6} showViewMoreButton={true} />
                        
                        </Tab>

                        <Tab eventKey="Web Design & Development" title="Web Design & Development">
                            <Portfolio2 limit={6} showViewMoreButton={true}/>
                            
                        </Tab>

                        <Tab eventKey="E-Commerce Solution" title="E-Commerce Solution">
                            <Portfolio3 limit={6} showViewMoreButton={true} />
                        </Tab>

                    </Tabs>
                    </div>
                </div>
                {/* End Portfolio Area */}


                {/* Start CounterUp Area */}
                <div className="rn-counterup-area ptb--120 bg_color--8">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Rapidly Growing</span>
                                    <h2 className="title text-white">Footsteps of Our Growth</h2>
                                    <p className="description">We've steadily grown in strength over the past few years.</p>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Our Proficiency</span>
                                    <h2 className="title">We Utilize Cutting-edge Technology</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--20">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </Fragment>
        )
    }
}
export default MainDemo;