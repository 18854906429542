import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>I'm so amazed to see the results. Working with Programaticlogic was a wonderful experience, and the people were so amazing they completed the task on the given deadline.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Angela Smith</span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>I'm Grateful for the Programatic Logic team's web design expertise and diligent support; we achieved project completion to our highest satisfaction. The team's swift and transparent approach left a lasting impression.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Alex Mathew</span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Programatic Logic excelled in delivering precisely what we needed—an error-free, visually pleasing web design. Their efficient and organized operation ensured on-time, budget-friendly task completion, while consistent communication enhanced our collaboration seamlessly.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>George Wills</span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Programatic Logic executed our vision flawlessly, delivering a user-friendly website that garnered highly positive feedback. The team's organization, responsiveness, communication, and creative approach, utilizing Zoom for project management, were exceptional. Their outstanding customer service further contributed to the project's success.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Ziv Levavi</span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Working with Programatic Logic has been seamless and instrumental in achieving desired outcomes. Their technical prowess and effective project management position them as a valuable partner. Their ability to comprehend and translate client ideas into reality contributes to a smooth workflow, yielding tangible results.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Mick Hardy</span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>The revamped website by Programatic Logic received high praise from our upper management, providing a refreshed aesthetic that kept the brand relevant during challenging times. Programatic Logic's knowledgeable and experienced team offered various ideas and information, fostering collaboration with our team for successful outcomes.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>David Thomas</span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Their insights genuinely impressed me, revealing aspects I wasn't aware of despite sharing the same professional background. Their depth of knowledge stood out, significantly impacting my perception. Well, to be honest, they delivered the best results and completed the project on time.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Richard Ryan</span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Hiring Programatic Logic was a pivotal decision that significantly shaped our current position. Their expert guidance has steered us in the perfect direction, showcasing their professionalism and ability to work wonders. I am truly amazed by their skills and grateful for their exceptional services.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Gary Brandon</span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                                <div className="col-lg-6 mt_md--40 mt_sm--40">
                                    <TabList className="testimonial-thumb-wrapper">
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <h6>Angela Smith</h6>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                   <h6>Alex Mathew</h6>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                   <h6>George Wills</h6>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                   <h6>Ziv Levavi</h6>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <h6>Mick Hardy</h6>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <h6>David Thomas</h6>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <h6>Richard Ryan</h6>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <h6>Gary Brandon</h6>
                                                </div>
                                            </div>
                                        </Tab>
                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;