import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
// import TermsAndCondition from "../elements/TermsAndCondition";
import { Link } from "react-router-dom";


class About extends Component {
    render() {
        const { title, parent } = this.props;
        return (
            <React.Fragment>
                <PageHelmet pageTitle='PrivacyPolicy' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />


                {/* Start Breadcrump Area */}
                <div className="breadcrumb-area-our  ptb--120 bg_image bg_image--23">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    <h2 className="title">Privacy Policy</h2>
                                    <ul className="page-list">
                                        <li className="rn-breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                        {parent ? <li className="rn-breadcrumb-item">{parent}</li> : ''}
                                        <li className="rn-breadcrumb-item active">Privacy Policy</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start termsandcondition area */}
                <section className="ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <span className="subtitle"  style={{ fontSize: '40px' }}>Privacy Policy</span>
                                    <p className="description">At Programatic Logic, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy delineates the procedures governing the collection, utilization, and protection of your data when you engage with our website and services.. We encourage you to review this policy to understand the measures we take to respect and protect your privacy. If you have any questions or concerns, please feel free to contact us. Your privacy is of the utmost importance to us, and we appreciate your trust in our handling of your information.</p>
                                </div>
                                
                                <div className="section-title mt-5">
                                    <h4 class="mb--15">What Data Do We Gather?</h4>
                                    <p className="description">In addition to your name or email address, we may also request additional information when you fill out a form on our site. This information is collected to enhance your experience and provide you with relevant services. Your privacy is important to us, and we want to assure you that any data collected is handled securely and in accordance with our privacy policy.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">For what purpose do we utilize the information you provide?</h4>
                                    <p className="description">The information we collect from you serves various purposes to enhance your overall experience:</p>

                                    <h5 class="mb--15">Improving Customer Service:</h5>
                                    <p className="description">Your details enable us to respond more efficiently to your customer service inquiries and provide necessary support.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h5 class="mb--15">Transaction Processing:</h5>
                                    <p className="description">We ensure that your information, whether public or private, remains confidential. It will not be sold, exchanged, transferred, or disclosed without your consent. This commitment is solely to fulfill the specific product or service you have requested through the transaction.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">What Measures Do We Take to Safeguard Your Information?</h4>
                                    <p className="description">In our commitment to safeguard your personal information, we utilize a range of security measures. These measures are in place to ensure the confidentiality and integrity of your data when you enter, submit, or access personal information on our platform. We continuously review and enhance our security protocols to adapt to evolving threats, providing you with a secure environment for interacting with our services.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Do We Use Cookies?</h4>
                                    <p className="description">Indeed, we employ the use of cookies.
                                    A cookie is a small piece of data stored on a computer to identify a browser during interactions on websites. Cookies can store information like identifiers and user preferences. A website can set a cookie to the browser if the browser's preferences allow it. It's important to note that a browser permits a website to access only the cookies it has set and not those set by other websites. Our use of cookies aims to enhance your browsing experience and tailor our services to better suit your preferences.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Do We Share Any Information With Third Parties?</h4>
                                    <p className="description">Our dedication to safeguarding your personally identifiable information means that we refrain from selling, trading, or sharing it with any external entities. This commitment extends to our collaboration with trusted third parties who assist in the operation of our website, customer service, or business activities, ensuring that they also uphold the confidentiality of this information. While respecting your privacy, there are situations where disclosure may be necessary to comply with legal obligations, enforce site policies, or protect the rights, property, or safety of ourselves and others. Moreover, non-personally identifiable visitor information might be shared with other parties for purposes such as marketing, advertising, or similar endeavors.</p>
                                </div>

                                <div className="section-title mt-5">
                                    <h4 class="mb--15">Your Consent:</h4>
                                    <p className="description">By utilizing our site, you signify your agreement with our online privacy policy. We assure you that the information you provide is secured and used solely for the purpose of enhancing your experience on our platform. We do not disclose your personally identifiable details to external entities, except for trusted third parties supporting our operations, business processes, or serving you, with a commitment to confidentiality. Rest assured, your privacy matters to us, and we comply with all applicable laws to safeguard your information. If you have any concerns or queries about our privacy practices, feel free to reach out to us.</p>
                                </div>
                               
                             

                             

                                
                            </div>
                        </div>
                    </div>
                </section>
                {/* End termsandcondition area */}

                

               


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default About