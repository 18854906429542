import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import OrderForm from "../elements/order/OrderForm";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import { Link } from "react-router-dom";

class Order extends Component {

    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        const { title, parent } = this.props;
        // console.log();
        const slug = this.props.match.params.param;
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Order Summary' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="breadcrumb-area-our  ptb--120 bg_image bg_image--19">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    <h2 className="title">Order Summary</h2>
                                    <ul className="page-list">
                                        <li className="rn-breadcrumb-item"><Link to="/">Home</Link></li>
                                        {parent ? <li className="rn-breadcrumb-item">{parent}</li> : ''}
                                        <li className="rn-breadcrumb-item active">Packages</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rn-contact-page ptb--120 bg_color--1">
                    <OrderForm slug={slug} />
                </div>

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </React.Fragment>
        )
    }
}
export default Order