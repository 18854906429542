import React, { Component } from "react";
import Portfolio1a from './portfolio1a';
import Portfolio2 from './portfolio2';
import Portfolio3 from './portfolio3';
import Portfolio4 from './portfolio4';
import Portfolio5 from './portfolio5';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';



class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
      
        return(
            <React.Fragment> 
                 <div className="portfolio-area ptb--20 bg_color--5">
                    <div className="container">
                    <Tabs defaultActiveKey="web" id="fill-tab-example" className="mb-3 mt-5" fill>

                        <Tab eventKey="web" title="Logo Design">
                            <Portfolio1a />
                        </Tab>

                        <Tab eventKey="Web Design & Development" title="Web Design & Development">
                            <Portfolio2 />
                        </Tab>

                        <Tab eventKey="E-Commerce Solution" title="E-Commerce Solution">
                            <Portfolio3 />
                        </Tab>

                        </Tabs>
                   
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default PortfolioList;