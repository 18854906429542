import PropTypes from "prop-types";
import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.pageTitle} | 360 Digital Solutions Hub Programatic Logic</title>
                    <meta name="robots" content="noindex, follow" />
                    <meta property="og:title" content={this.props.pageTitle + " | 360 Digital Solutions Hub Programatic Logic"} />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Programatic Logic" />
                    <meta property="og:image" content="/assets/images/logo/headerlogo.png" />
                    <meta name="og:description" content="Are you looking for a software house in Karachi? Look no further as Programatic logic is the best software house offering marketing services." />
                    <meta name="description" content="Unlock Digital Excellence with us – Logo Design, Web Development, E-commerce Solutions." />
                    <meta name="keywords" content="Logo Design, Web Design, Web Development, E-commerce Solutions, Custom Logo Design, Responsive Web Design, E-commerce Development, Digital Solutions, Programatic Logic Web Design" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                </Helmet>
            </React.Fragment>
        )
    }
}
PageHelmet.propTypes = {
    title: PropTypes.string
};
export default PageHelmet;
