import React, { Component } from "react";
import "./style.css";
import { FiCheck } from "react-icons/fi";
const portfolio4 = () => {
    return (
       <div>
        <section className="portfolioboxes">
        <div className="container-custom">
          <div classname="row">
          <div className="col-lg-12">
              <div className="portwrappp">
                <div className="tab-custom">
                <ul className="tabs tabs-animationport mportt">
        <li className="to-win-box">
          <a data-fancybox href="javascript:;" data-type="iframe" data-src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/7-signal.mp4">
          </a><figure><a data-fancybox href="javascript:;" data-type="iframe" data-src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/7-signal.mp4">
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/7-signal.png" />
              <ure>
              </ure></a>
          </figure></li>
        <li className="to-win-box">
          <a data-fancybox href="javascript:;" data-type="iframe" data-src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/blackboard.mp4">
          </a><figure><a data-fancybox href="javascript:;" data-type="iframe" data-src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/blackboard.mp4">
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/blackboard.png" />
              <ure>
              </ure></a>
          </figure></li>
        <li className="to-win-box">
          <a data-fancybox href="javascript:;" data-type="iframe" data-src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/ice-cold-mouthpiece.mp4">
          </a><figure><a data-fancybox href="javascript:;" data-type="iframe" data-src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/ice-cold-mouthpiece.mp4">
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/ice-cold-mouthpiece.png" />
              <ure>
              </ure></a>
          </figure></li>
        <li className="to-win-box">
          <a data-fancybox href="javascript:;" data-type="iframe" data-src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/mark-eat.mp4">
          </a><figure><a data-fancybox href="javascript:;" data-type="iframe" data-src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/mark-eat.mp4">
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/mark-eat.png" />
              <ure>
              </ure></a>
          </figure></li>
        <li className="to-win-box">
          <a data-fancybox href="javascript:;" data-type="iframe" data-src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/medicare.mp4">
          </a><figure><a data-fancybox href="javascript:;" data-type="iframe" data-src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/medicare.mp4">
              <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/video/medicare.png" />
              <ure>
              </ure></a>
          </figure></li>
      </ul>

                </div>
                </div>
                </div>
                </div>
                </div>
                </section>
       </div>


    )
}
export default portfolio4;