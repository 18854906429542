import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiSmartphone ,FiChevronUp, FiMail, FiCopy } from "react-icons/fi";
import { ProgressBar } from 'react-bootstrap';


const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Logo Design',
        description: "Our custom logo design services offer everything you need to kickstart your business. Tailored logos for your brand's unique identity."
    },

    {
        icon: <FiLayers />,
        title: 'E-Commmerce Solution',
        description: 'We apply e-commerce expertise, solve issues, and boost revenue while reducing costs for your online store. Tangible improvements guaranteed.'
    }
    ,
    {
        icon: <FiLayers />,
        title: 'Web Design & Development',
        description: 'Our experts guarantee a search-engine-friendly, visually appealing, and user-friendly website with top-notch web design and development skills.'
    }
   
]

class ServiceTwo extends Component{
    render(){
        let title = 'Our Methodology',
        subtitle= "Let's Create A Successful Business Together",
        description = "We adopt a professional approach to tackle tasks, no matter how intricate or advanced. From web development to web design and e-commerce solutions, we employ cutting-edge techniques to conquer every challenge with expertise and precision.";
        return(
            <React.Fragment>
                {/* <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center"> */}
                                {/* <span className="subtitle">{subtitle}</span> */}
                                {/* <h2 className="title text-white">{title}</h2> */}
                                {/* <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p> */}
                            {/* </div>
                        </div>
                    </div> */}

                    {/* <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {ServiceList.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a href="/service-details">
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}
                  
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="section-title">
                                    <span className="subtitle">{subtitle}</span>
                                    <h2 className="title text-white">{title}</h2>
                                    <p className="description text-white" dangerouslySetInnerHTML={{ __html: description }}></p>
                                    <div class="view-more-btn mt--60 mt_sm--30"><a class="btn-default" href="/contact"><span>Get A Quote</span></a></div>

                                    <div className={`rn-progress-bar progress-bar--1 mt_dec--10`}>
                                                <div className="single-progress">
                                                    <h6 className="title text-white">Designing</h6>
                                                    <ProgressBar now={95} />
                                                    <span className="label">95%</span>
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title text-white">Development</h6>
                                                    <ProgressBar now={95} />
                                                    <span className="label">95%</span>
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title text-white">Customer Satisfaction</h6>
                                                    <ProgressBar now={98} />
                                                    <span className="label">98%</span>
                                                </div>

                                                <div className="single-progress">
                                                    <h6 className="title text-white">Time to Time Delivery</h6>
                                                    <ProgressBar now={95} />
                                                    <span className="label">95%</span>
                                                </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 services-section">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="service-card1">
                                            <div className="icon-dev">
                                                <img className="" src="/assets/images/logodesign.png" alt="About Images"/>
                                            </div>
                                            <div className="content">
                                                <h2>Logo Design</h2>
                                                <p>Our enchanting logo creation elevates your business image, providing a globally recognized and eye-catching brand identity across diverse domains.</p>
                                                <br />
                                                
                                                <div className="purchase-btn">
                                                    <a className="btn-transparent" href="/logo-design">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 maincolumn ">
                                        <div className="service-card2">
                                            <div className="icon-dev">
                                                <img className="" src="/assets/images/webdesign.png" alt="About Images"/>
                                            </div>
                                            <div className="content">
                                                <h2>Website Development</h2>
                                                <p>Our demonstrated success in web development and design projects propels us forward. We are committed to delivering nothing short of the best.</p>
                                                
                                                <div className="purchase-btn">
                                                    <a className="btn-transparent" href="/logo-design">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                    <div className="service-card3">
                                            <div className="icon-dev">
                                                <img className="" src="/assets/images/ecommerce.png" alt="About Images"/>
                                            </div>
                                            <div className="content">
                                                <h2>E-Commerce Solution</h2>
                                                <p>Elevate your business in the competitive market with our e-commerce solution. Our experts tailor a perfect solution aligning with your business goals.</p>
                                                
                                                <div className="purchase-btn">
                                                    <a className="btn-transparent" href="/logo-design">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
