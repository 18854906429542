import React from 'react'
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp , FiCheck } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { Link } from 'react-router-dom';
const pricing = () => {
    return (
<>
<PageHelmet pageTitle='Portfolio' />
 {/* Start Page Wrapper  */}
 <main className="page-wrapper">
 {/* Start Pricing Tbale Area  */}
 <div className="design" id="design">
 <div className="rn-pricing-table-area ptb--120 ">
     <div className="container">
         <div className="row">

             {/* Start PRicing Table Area  */}
             <div className="col-lg-4 col-md-6 col-12 mt--30">
                 <div className="rn-pricing">
                     <div className="pricing-table-inner">
                         <div className="pricing-header">
                             <h4 className="title">Free</h4>
                             <div className="pricing">
                                 <span className="price">29</span>
                                 <span className="subtitle">USD Per Month</span>
                             </div>
                         </div>
                         <div className="pricing-body">
                             <ul className="list-style--1">
                                 <li><FiCheck /> 5 PPC Campaigns</li>
                                 <li><FiCheck /> Digital Marketing</li>
                                 <li><FiCheck /> Marketing Agency</li>
                                 <li><FiCheck /> Seo Friendly</li>
                                 <li><FiCheck /> UI/UX designs</li>
                             </ul>
                         </div>
                         <div className="pricing-footer">
                             <a className="rn-btn" href="#pricing">Purchase Now</a>
                         </div>
                     </div>
                 </div>
             </div>
             {/* End PRicing Table Area  */}

             {/* Start PRicing Table Area  */}
             <div className="col-lg-4 col-md-6 col-12 mt--30">
                 <div className="rn-pricing active">
                     <div className="pricing-table-inner">
                         <div className="pricing-header">
                             <h4 className="title">Business</h4>
                             <div className="pricing">
                                 <span className="price">29</span>
                                 <span className="subtitle">USD Per Month</span>
                             </div>
                         </div>
                         <div className="pricing-body">
                             <ul className="list-style--1">
                                 <li><FiCheck /> 5 PPC Campaigns</li>
                                 <li><FiCheck /> Digital Marketing</li>
                                 <li><FiCheck /> Marketing Agency</li>
                                 <li><FiCheck /> Seo Friendly</li>
                                 <li><FiCheck /> UI/UX designs</li>
                             </ul>
                         </div>
                         <div className="pricing-footer">
                             <a className="rn-btn" href="#pricing">Purchase Now</a>
                         </div>
                     </div>
                 </div>
             </div>
             {/* End PRicing Table Area  */}

             {/* Start PRicing Table Area  */}
             <div className="col-lg-4 col-md-6 col-12 mt--30">
                 <div className="rn-pricing">
                     <div className="pricing-table-inner">
                         <div className="pricing-header">
                             <h4 className="title">Advanced</h4>
                             <div className="pricing">
                                 <span className="price">29</span>
                                 <span className="subtitle">USD Per Month</span>
                             </div>
                         </div>
                         <div className="pricing-body">
                             <ul className="list-style--1">
                                 <li><FiCheck /> 5 PPC Campaigns</li>
                                 <li><FiCheck /> Digital Marketing</li>
                                 <li><FiCheck /> Marketing Agency</li>
                                 <li><FiCheck /> Seo Friendly</li>
                                 <li><FiCheck /> UI/UX designs</li>
                             </ul>
                         </div>
                         <div className="pricing-footer">
                             <a className="rn-btn" href="#pricing">Purchase Now</a>
                         </div>
                     </div>
                 </div>
             </div>
             {/* End PRicing Table Area  */}
             
         </div>
         <div className="row">

             {/* Start PRicing Table Area  */}
             <div className="col-lg-4 col-md-6 col-12 mt--30">
                 <div className="rn-pricing">
                     <div className="pricing-table-inner">
                         <div className="pricing-header">
                             <h4 className="title">Free</h4>
                             <div className="pricing">
                                 <span className="price">29</span>
                                 <span className="subtitle">USD Per Month</span>
                             </div>
                         </div>
                         <div className="pricing-body">
                             <ul className="list-style--1">
                                 <li><FiCheck /> 5 PPC Campaigns</li>
                                 <li><FiCheck /> Digital Marketing</li>
                                 <li><FiCheck /> Marketing Agency</li>
                                 <li><FiCheck /> Seo Friendly</li>
                                 <li><FiCheck /> UI/UX designs</li>
                             </ul>
                         </div>
                         <div className="pricing-footer">
                             <a className="rn-btn" href="#pricing">Purchase Now</a>
                         </div>
                     </div>
                 </div>
             </div>
             {/* End PRicing Table Area  */}

             {/* Start PRicing Table Area  */}
             <div className="col-lg-4 col-md-6 col-12 mt--30">
                 <div className="rn-pricing active">
                     <div className="pricing-table-inner">
                         <div className="pricing-header">
                             <h4 className="title">Business</h4>
                             <div className="pricing">
                                 <span className="price">29</span>
                                 <span className="subtitle">USD Per Month</span>
                             </div>
                         </div>
                         <div className="pricing-body">
                             <ul className="list-style--1">
                                 <li><FiCheck /> 5 PPC Campaigns</li>
                                 <li><FiCheck /> Digital Marketing</li>
                                 <li><FiCheck /> Marketing Agency</li>
                                 <li><FiCheck /> Seo Friendly</li>
                                 <li><FiCheck /> UI/UX designs</li>
                             </ul>
                         </div>
                         <div className="pricing-footer">
                             <a className="rn-btn" href="#pricing">Purchase Now</a>
                         </div>
                     </div>
                 </div>
             </div>
             {/* End PRicing Table Area  */}

             {/* Start PRicing Table Area  */}
             <div className="col-lg-4 col-md-6 col-12 mt--30">
                 <div className="rn-pricing">
                     <div className="pricing-table-inner">
                         <div className="pricing-header">
                             <h4 className="title">Advanced</h4>
                             <div className="pricing">
                                 <span className="price">29</span>
                                 <span className="subtitle">USD Per Month</span>
                             </div>
                         </div>
                         <div className="pricing-body">
                             <ul className="list-style--1">
                                 <li><FiCheck /> 5 PPC Campaigns</li>
                                 <li><FiCheck /> Digital Marketing</li>
                                 <li><FiCheck /> Marketing Agency</li>
                                 <li><FiCheck /> Seo Friendly</li>
                                 <li><FiCheck /> UI/UX designs</li>
                             </ul>
                         </div>
                         <div className="pricing-footer">
                             <a className="rn-btn" href="#pricing">Purchase Now</a>
                         </div>
                     </div>
                 </div>
             </div>
             {/* End PRicing Table Area  */}
             
         </div>
     </div>
 </div>

 </div>
 
 {/* End Pricing Tbale Area  */}
</main>
{/* End Page Wrapper  */}
</>
)}
export default pricing;