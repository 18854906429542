
import React, { useState } from "react";
import "./style1.css";
import FsLightbox from "fslightbox-react";

const Portfolio1a = (props) => {
  const [toggler, setToggler] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const toggleToggler = (item) => {
    setToggler(!toggler);
    setActiveItem(item);
  };

  const portfolioItems = [
    { id: 1, image: "/assets/images/portfolio/web-dev/web1.webp", alt: "web-portfolio1" },
    { id: 2, image: "/assets/images/portfolio/web-dev/web2.webp", alt: "web-portfolio2" },
   
    // Add more portfolio items here
  ];

  const itemsToDisplay = portfolioItems.slice(0, props.limit);

  return (
    <div>
      <section className="portfolioboxes">
        <div className="container-custom">
          <div className="row">
            <div className="col-lg-12">
              <div className="portwrappp">
                <div className="tab-custom">
                  <ul className="tabs tabs-logoport mportt">
                    {itemsToDisplay.map((item) => (
                      <li className="to-win-box" key={item.id}>
                        <figure>
                          <img onClick={() => toggleToggler(item)} src={item.image} alt={item.alt} />
                        </figure>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {props.showViewMoreButton && ( // Check the prop here
          <div class="row">
            <div class="col-lg-12">
              <div class="view-more-btn mt--60 mt_sm--30 text-center">
                <a class="btn-default" href="/portfolio">
                  <span>View More</span>
                </a>
              </div>
            </div>
          </div>
            )}
        </div>
        <FsLightbox
          toggler={toggler}
          sources={[activeItem ? activeItem.image : ""]}
        />
      </section>
    </div>
  );
};

export default Portfolio1a;