import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn } from "react-icons/fa";
import { NavLink } from 'react-router-dom';

import { FiX , FiMenu} from "react-icons/fi";
import Scrollspy from 'react-scrollspy'

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]
class HeaderThree extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
        
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader () {}
    render(){
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color='default-color' } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
        }else if(logo === 'dark'){
            logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
        }else if(logo === 'symbol-light'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
        }else{
            logoUrl = <img src="/assets/images/logo/headerlogo.png" alt="Digital Agency" />;
        }
        
        return(
          <header className={`header-area header-style-two header--fixed ${color}`}>
            <div className="header-wrapper" id="header-wrapper">
                <div className="header-left">
                    <div className="logo">
                        <a href="/">
                            {logoUrl}
                        </a>
                    </div>
                </div>
                <div className="header-right">
                <nav className="mainmenunav d-lg-block">
                    <ul className="mainmenu">
                        <li className="has-droupdown">
                            <NavLink exact to="/" className="homelink" activeClassName="active-link">Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about" activeClassName="active-link">About Us</NavLink>
                        </li>
                        <li className="has-droupdown">
                            <NavLink to="/service" activeClassName="active-link">Services</NavLink>
                            <ul className="submenu">
                                <li><NavLink to="/logo-design">Logo Design</NavLink></li>
                                <li><NavLink to="/website-design">Web Design & Development</NavLink></li>
                                <li><NavLink to="/ecommerce-solution">E-Commerce Solution</NavLink></li>
                            </ul>
                        </li>
                        <li className="has-droupdown">
                            <NavLink to="/portfolio" activeClassName="active-link">Portfolio</NavLink>
                        </li>
                       
                        <li className="has-droupdown">
                            <NavLink to="/packages" activeClassName="active-link">Packages</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact" activeClassName="active-link">Contact</NavLink>
                        </li>
                    </ul>
                </nav>

                {/* Start Humberger Menu */}
                <div className="humberger-menu d-block d-lg-none">
                    <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                </div>
                {/* End Humberger Menu */}
                
                <div className="close-menu d-block d-lg-none">
                    <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                </div>
            </div>
                <div className="header-btn d-none d-lg-block">
                        <a className="btn-default" href="tel:923313078379">
                            <span>+(92) 331 307 8379</span>
                        </a>
                    </div>
            </div>
            
        </header>
        )
    }
}
export default HeaderThree;