import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp , FiCheck } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import Packages from "./package";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from "react-router-dom";

const PricingTable = () => {
    return (
        <>
            <PageHelmet pageTitle='Our Packages' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
                   {/* Start Breadcrump Area */}
                   <div className="breadcrumb-area-our  ptb--120 bg_image bg_image--16">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    <h2 className="title">Our Packages</h2>
                                    <ul className="page-list">
                                        <li className="rn-breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="rn-breadcrumb-item active">Our Packages</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Pricing Tbale Area  */}
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                    <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="title">Let's Craft Extraordinary Experiences Tailored Just for You</h2>
                                    <p className="description">We bring your vision of owning a dynamic website to life, empowering your online business with a fully optimized digital presence. Take control and lead with our diverse package offerings, encompassing web design, web development, e-commerce solutions, logo design, and more. We possess the expertise and tools to make your online presence visually captivating and user-friendly. Order now to dominate the digital landscape with our exceptional web management services.</p>
                                </div>
                            </div> 
                        </div>
                       
                        <Tabs defaultActiveKey="design" id="fill-tab-example" className="mb-3 ptb--50" fill>
                            <Tab eventKey="design" title="Logo Design">
                              <Packages  name='design' />
                            </Tab>
                            <Tab eventKey="web" title="Website Design And Development">
                              <Packages name='web' />
                            </Tab>
                            <Tab eventKey="ecmmerce-solution" title="E-Commerce Solution">
                              <Packages name='ecmmerce-solution' />
                            </Tab>
                           </Tabs>                            
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}
        </>
        
    )
}
export default PricingTable;