import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import BrandTwo from "../elements/BrandTwo";
import { Link } from "react-router-dom";
import Packages from '../blocks/package';

class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const { title, parent } = this.props;
        return(
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='E-Commerce Solution' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

               {/* Start Breadcrump Area */}
               <div className="breadcrumb-area-our  ptb--120 bg_image bg_image--20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    <h2 className="title">E-Commerce Solution</h2>
                                    <ul className="page-list">
                                        <li className="rn-breadcrumb-item"><Link to="/">Home</Link></li>
                                        {parent ? <li className="rn-breadcrumb-item">{parent}</li> : ''}
                                        <li className="rn-breadcrumb-item active">E-Commerce Solution</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/ecommer.webp" alt="Service Images"/>
                                    
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        {/* <span className="subtitle">Website Designing and Development</span> */}
                                                        <h2 className="title">Your Premier Destination for E-Commerce Excellence!</h2>
                                                        <p className="description">We specialize in crafting digital shopping experiences and comprehensive digital marketing strategies tailored to achieve ambitious sales targets for your e-commerce venture. Whether you're dealing in small-scale products or luxury supercars, our team of user experience designers, web developers, and digital strategists thrives on tackling the intricacies of each challenge. Our goal is to ensure that your customers enjoy an e-commerce experience that is not only thoughtfully simple but also boldly memorable in its ambition.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        <span className="subtitle">Choose Success, Partner with Programatic Logic</span>
                                                            <h2 className="title">Reliable E-commerce Design Partner</h2>
                                                            <p className="description">Programatic Logic is a trusted partner in the online store realm, boasting extensive expertise in every facet. Our track record involves assisting numerous businesses, irrespective of size, to transform their websites into lucrative ventures. We take the time to understand your specific needs and craft a personalized plan tailored just for you. Our commitment to ongoing support sets us apart, ensuring that everything operates seamlessly. When you choose us, you're not just acquiring a website; you're gaining a dedicated partner in your journey to success.</p>
                                                            <ul class="list-style--1 mt--30">
                                                                <li><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="20 6 9 17 4 12"></polyline></svg> 
                                                                Tailored Strategies: We create customized plans aligned with the unique needs of your business, steering clear of a generic, one-size-fits-all approach.</li>

                                                                <li><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="20 6 9 17 4 12"></polyline></svg> 
                                                                Proven Track Record: Our previous projects stand as a testament to our success, consistently elevating sales and customer satisfaction.</li>

                                                                <li><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="20 6 9 17 4 12"></polyline></svg> 
                                                                Continuous Support: Count on us for ongoing support to ensure your website maintains its optimal performance.</li>

                                                                <li><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="20 6 9 17 4 12"></polyline></svg> 
                                                                Skilled Team: Our team of experts possesses in-depth knowledge of e-commerce and web design, making us well-equipped to handle your project with precision.</li>

                                                                <li><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="20 6 9 17 4 12"></polyline></svg> 
                                                                Client-Centric Approach: Your goals take center stage in our operations; we prioritize your needs and objectives in everything we undertake.</li>
                                                            </ul>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                <img className="w-100" src="/assets/images/service/ecommer.webp" alt="Service Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}


                 {/* Start Brand Area */}
                 <div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Our Expertise</span>
                                    <h2 className="title">Technologies We Use</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--20">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}


                {/* Start Pricing Tbale Area  */}
                <Packages  name='ecmmerce-solution' />
                {/* End Pricing Tbale Area  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;