import React from 'react';

import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import PortfolioList from "../elements/portfolio/PortfolioList";
import { Link } from "react-router-dom";

const Portfolio = () => {
    return (
        <>
            <PageHelmet pageTitle='Portfolio' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
           {/* Start Breadcrump Area */}
           <div className="breadcrumb-area-our  ptb--120 bg_image bg_image--16">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    <h2 className="title">Portfolio</h2>
                                    <ul className="page-list">
                                        <li className="rn-breadcrumb-item"><Link to="/">Home</Link></li>
                                       
                                        <li className="rn-breadcrumb-item active">Portfolio</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}
                
            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <span className="subtitle">Crafting Bold Solutions for an Evolving World</span>
                                       
                                        <p className="description">Discover a showcase of the stunningly beautiful and expertly crafted projects we've proudly presented to our extraordinary clients.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                           
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

              
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}
        </>
    )
}

export default Portfolio;