import React, { Component } from "react";
import "./style1.css";
import { FiCheck } from "react-icons/fi";
const portfolio1a = () => {
    return (
        <div>
            <section className="portfolioboxes">
        <div className="container-custom">
          <div classname="row">
          <div className="col-lg-12">
              <div className="portwrappp">
                <div className="tab-custom">
                  <ul className="tabs tabs-logoport mportt">
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/1.jpg">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/1.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/uploads/portfolio/103728-Logo011.jpg">
                        <figure>
                          <img height="300px" src="https://www.softbitdigital.com/uploads/portfolio/103728-Logo011.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/uploads/portfolio/48586-Logo021.jpg">
                        <figure>
                          <img height="300px" src="https://www.softbitdigital.com/uploads/portfolio/48586-Logo021.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/uploads/portfolio/51074-Logo03.jpg">
                        <figure>
                          <img height="300px" src="https://www.softbitdigital.com/uploads/portfolio/51074-Logo03.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/uploads/portfolio/49231-Logo291.jpg">
                        <figure>
                          <img height="300px" src="https://www.softbitdigital.com/uploads/portfolio/49231-Logo291.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/uploads/portfolio/52584-Logo051.jpg">
                        <figure>
                          <img height="300px" src="https://www.softbitdigital.com/uploads/portfolio/52584-Logo051.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/2.jpg">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/2.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/3.gif">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/3.gif" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/4.jpg">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/4.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/16.gif">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/16.gif" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/6.jpg">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/6.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/7.jpg">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/7.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/8.jpg">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/8.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/9.jpg">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/9.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/10.jpg">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/10.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/11.gif">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/11.gif" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/12.jpg">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/12.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/13.jpg">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/13.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/14.jpg">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/14.jpg" />
                        </figure>
                      </a>
                    </li>
                    <li className="to-win-box">
                      <a data-fancybox="port" href="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/15.jpg">
                        <figure>
                          <img src="https://www.softbitdigital.com/front-end/assets/images/portfolio/logo/15.jpg" />
                        </figure>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            </div>
          </div>
          </section>
        </div>
      
// {/* <div className="col-md-4">
//             <img className="w-100" src="/assets/images/team/team-01.jpg" alt="Blog Images"/>

//             </div>
//             <div className="col-md-4">
//             <img className="w-100" src="/assets/images/team/team-01.jpg" alt="Blog Images"/>

//             </div>
//             <div className="col-md-4">
//             <img className="w-100" src="/assets/images/team/team-01.jpg" alt="Blog Images"/>
                
//             </div> */}
    )
}
export default portfolio1a;