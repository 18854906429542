import React, { Component } from "react";
import { FiUserPlus } from "react-icons/fi";
import { Link, withRouter } from 'react-router-dom';

class ParentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideButton: this.props.location.pathname === '/about', // Initially hide the button on the About page
        };
    }

    render() {
        return (
            <div>
                {/* Other content */}
                <About hideButton={this.state.hideButton} />
            </div>
        );
    }
}

class About extends Component {
    render() {
        let title = 'About Programatic Logic';
        let description = 'Welcome to Programatic Logic, your trusted ally in digital excellence. Specializing in web development, web design, and e-commerce development, we stand as architects of your online success. Our dynamic team crafts personalized marketing solutions, staying at the forefront by integrating the latest trends. This ensures impactful engagement with diverse customer groups. ';
        let description2 = "At Programatic Logic, surpassing expectations is not just a goal; it's our tradition. We foster a legacy of client success and satisfaction, going beyond the ordinary. Choose Programatic Logic for a transformative journey where we enhance your digital footprint and secure outstanding results in the ever-evolving realm of online business.";

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <FiUserPlus />
                                        </div>
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>

                                        {/* Conditionally render the button based on the hideButton prop */}
                                        {!this.props.hideButton && (
                                            <div className="purchase-btn">
                                                <Link className="btn-transparent" to="/about">Read More</Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(ParentComponent);
