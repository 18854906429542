import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp, FiMail, FiCopy } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import { Link } from "react-router-dom";

const logodesinService = [
    {
        link: '/logo-design',
        icon: <FiCast />,
        title: 'Select Design Package',
        description: 'In simple terms, our custom logo design packages offer varying quantities of initial concepts, rounds of revisions, and selected styles tailored to each option.'
    },
    {
        link: '/logo-design',
        icon: <FiLayers />,
        title: 'Complete Design Brief',
        description: 'Our designers, though not mind readers, excel in their craft. After you order, please complete our simple design brief. We ensure industry trends and insights throughout the process.'
    },

    {
        link: '/logo-design',
        icon: <FiLayers />,
        title: 'Collaborate & Finalize',
        description: "We'll collaborate throughout the process to ensure your new logo design leaves you over the moon with satisfaction."
    },
   
]

const webDevolop = [
    {
        icon: <FiCast />,
        title: 'HTML5 Web Design & Development',
        description: 'Our skilled HTML5 developers provide tailored solutions, optimizing website performance with a focus on scalability, loading time, and responsiveness to suit your business environment.'
    },
    {
        icon: <FiLayers />,
        title: 'WordPress Development & Web Design',
        description: 'With unmatched expertise, we craft engaging, custom WordPress websites. Our solutions guarantee high-performance, secure, scalable, and feature-rich digital products, enhancing brand presence for businesses.'
    },

    {
        icon: <FiLayers />,
        title: 'Laravel Web Development Solutions',
        description: 'Our skilled Laravel developers employ strong methodologies to design and build web-based products meeting industry standards. Our Laravel services ensure businesses achieve accelerated results.'
    },
   
   
]

const EcomSolution = [
    {
        link: '/servicesdetail',
        icon: <FiCast />,
        title: 'Magento Web Development',
        description: 'Widely embraced by remote e-commerce developers, Magento stands as the most powerful platform. Our developers strategically plan your online store using this cost-effective platform, supported by various tools.'
    },
    {
        icon: <FiLayers />,
        title: 'Shopify Web Development',
        description: 'Our eCommerce developers fulfill your business needs through the Shopify platform. Hiring developers ensures mobile-friendly, secure, and flexible web solutions, fostering business growth.'
    },

    {
        icon: <FiLayers />,
        title: 'WooCommerce Web Development',
        description: 'WooCommerce, an open-source plugin for web development, is favored by e-commerce developers for projects of all sizes. Businesses choose our WooCommerce website development services for their diverse needs.'
    },
   
]



class Service extends Component{
    render(){
        const { title, parent } = this.props;
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Service' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="breadcrumb-area-our  ptb--120 bg_image bg_image--16">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    <h2 className="title">Services</h2>
                                    <ul className="page-list">
                                        <li className="rn-breadcrumb-item"><Link to="/">Home</Link></li>
                                        {parent ? <li className="rn-breadcrumb-item">{parent}</li> : ''}
                                        <li className="rn-breadcrumb-item active">Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Service Area */}
                <div className="service-area ptb--120" style={{ backgroundColor: 'rgb(243 243 243)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                <span className="subtitle">Our Creatity</span>
                                    <h2>Logo Designing</h2>
                                    <p>Our logo designers craft 100% unique designs, creating memorable masterpieces that proudly represent your brand identity with distinction and pride.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-main-wrapper">
                            {logodesinService.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={val.link}>
                                        <div className="service service__style--2 text-left">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--8">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Crafting Masterpiece</span>
                                    <h2 className="text-white">Our Development and Design Methodology</h2>
                                    <p className="text-white">We leverage cutting-edge web technologies to overcome intricate business challenges in the digital era, propelling your business leaps ahead of the competition.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-main-wrapper">
                            {webDevolop.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/website-design">
                                        <div className="service service__style--2 text-left">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120" style={{ backgroundColor: 'rgb(243 243 243)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                <span className="subtitle">Ecommerce Development</span>
                                <h2>User-centric Solutions</h2>
                                    <p>Empower your business growth and enhance your e-commerce strategy with our skilled software developers. Partner with us for user-centric solutions tailored to your business model.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-main-wrapper">
                            {EcomSolution.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/ecommerce-solution">
                                        <div className="service service__style--2 text-left">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;